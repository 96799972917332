@import url('https://fonts.googleapis.com/css2?family=Maison+Neue&display=swap');
.main {
    display: flex;
    flex-direction: column;
}

.heading {
    /* display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgb(249, 242, 241); */
    margin-top: 88%;
    /* padding-top: 65%; */
    /* width: 100% !important; */
    padding-bottom: 16%;
    /* height: 500px; */
}

h1 {
    font-size: 48px;
    margin-bottom: 20px;
}

.cta-button {
    background-color: rgb(222, 76, 31);
    color: white;
    padding: 10px 22px;
    border: none;
    border-radius: 28px;
    text-decoration: none;
    font-weight: 600;
    font-size: 26px;
    cursor: pointer;
    margin-top: 18px;
}

.addOne {
    background-color: white;
}

.inner {
    background-color: #E8E8ED;
    margin: 15px;
    display: flex;
    justify-content: space-between;
}

.itemOne {
    width: 55%;
    padding: 3% 6%;
}

.features {
    height: 680px;
    padding: 7%;
}

.banner {
    height: 100px;
    z-index: -1;
}

@media (max-width: 600px) {
    .heading {
        display: flex;
        flex-direction: column;
        margin-top: 65%;
        padding-top: 45%;
        /* padding-left: 18%; */
        align-items: center;
        justify-content: center;
        /* margin-bottom: 30%; */
        padding-bottom: 50%;
    }
}
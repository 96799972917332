.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* height: 100vh; */
    background-color: rgb(249, 242, 241);
    padding-top: 23%;
    width: 100% !important;
    /* margin-bottom: 30%; */
    /* padding-bottom: 200px; */
}

h1 {
    font-size: 48px;
    margin-bottom: 20px;
}

.cta-button {
    background-color: rgb(222, 76, 31);
    color: white;
    padding: 10px 22px;
    border: none;
    border-radius: 28px;
    text-decoration: none;
    font-weight: 600;
    font-size: 26px;
    cursor: pointer;
    margin-top: 18px;
}
.content {
    background-color: rgb(249, 242, 241);
    display: flex;
    flex-direction: column;
}

section {
    padding-top: 8%;
    height: 100vh;
}

.pricing-plans {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-left: 10%;
    padding-right: 10%;
}

.heading {
    justify-content: center;
}

.plan {
    width: calc(33.33% - 20px);
    background-color: white;
    padding: 40px;
    text-align: center;
    border-radius: 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin: 10px;
    transition: all 0.2s ease-in-out;
}

.plan ul {
    list-style: none;
}

.plan:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.selected {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    transform: translateY(-10px);
}

@media (max-width: 768px) {
    .plan {
        width: calc(100% - 20px);
    }
}
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    padding: 0 20px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: transparent;
    transition: background-color 0.5s ease;
    z-index: 10;
}

.white {
    background-color: white;
}

.logos {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.logos h1 {
    font-weight: 600;
    font-size: 22px;
    color: rgb(222, 76, 31);
    text-decoration: none;
}

.logo {
    height: 50px;
    cursor: pointer;
}

.menu {
    display: flex;
    padding-top: 1.9%;
}

.menu a {
    margin-right: 20px;
    font-size: 16px;
    text-decoration: none;
    color: black;
}

.nav-link.active {
    /* background-color: rgb(222, 76, 31); */
    border-bottom: 2px solid rgb(222, 76, 31);
}

.menu .authLinks {
    display: none;
}

.authentications {
    display: flex;
}

#mobileMenu {
    display: none;
    margin-right: 20px;
    font-size: 16px;
    text-decoration: none;
    color: black;
}

.btn {
    margin-left: 20px;
    padding: 8px 18px;
    border: none;
    border-radius: 25px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
}

.btn-primary {
    background-color: rgb(222, 76, 31);
    color: white;
}

.btn-secondary {
    background-color: lightgray;
    color: black;
}

@media (max-width: 600px) {
    nav a {
        display: none;
    }
    .authentications {
        display: none;
    }
    .menu .authLinks {
        display: block;
    }
    #mobileMenu {
        display: block !important;
    }
    .menu-toggle {
        display: block;
        background-color: cornflowerblue;
        color: white;
        padding: 15px 20px;
        cursor: pointer;
    }
    .menu-toggle:hover {
        background-color: deepskyblue;
    }
}